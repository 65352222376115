import { ref, computed, watch } from '@vue/composition-api'
// import { title } from '@core/utils/filter'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment-timezone'
import store from '@/store'
import router from '@/router'
import ability from '@/libs/acl/ability'
import { debounce } from 'lodash'

export default function useList() {
  const refListTable = ref(null)

  const toast = useToast()

  const tableColumns = [
    { label: 'shared_tenant.name', key: 'tenant.name', sortable: true },
    { label: 'shared_tenant.permissions', key: 'permissions', sortable: false, formatter: (value, key, item) => {
      if(value) return value.map(item => { return item.description}).join(', ')
      return ""
    }, thStyle: 'min-width: 500px'},
    { label: 'shared_tenant.type_alarms', key: 'type_alarms', sortable: false, formatter: (value, key, item) => {
      if(value) return value.map(item => { return item.description}).join(', ')
      return ""
    }, thStyle: 'min-width: 500px'},
    { label: 'actions.name', key: 'actions' }
  ]
  // if (ability.can('update', 'Center') == true || ability.can('delete', 'Center') == true) {
  //   tableColumns.splice(6, 1, { label: 'actions.name', key: 'actions' })

  // }

  let id_group = router.currentRoute.params.id_group
  console.log({id_group})

  const perPage = ref(10)
  const totalList = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalList.value,
    }
  })


  const fetchList = (ctx, callback) => {
    store.dispatch('group_shared_tenant/getAll', {
      id: id_group,
      params : {
        q: searchQuery.value,
        max: perPage.value,
        'page[n]': currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      }
    })
      .then(response => {
        callback(response.data)
        totalList.value = response.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error obteniendo la información',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const formatDateAssigned = value => {
    const formattedDate = moment(value).format('DD/MM/YYYY HH:mm:ss')
    return formattedDate
  }

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([currentPage, perPage], () => {
    refetchData()
  })

  watch([ searchQuery ], debounce(() => {
    refetchData()
  }, 500))

  return {
    tableColumns,
    perPage,
    currentPage,
    totalList,
    fetchList,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    formatDateAssigned,
    refetchData,
  }
}
